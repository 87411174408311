import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncSkeletonPage = React.lazy(() => import('../paying-members.skeleton'));

const routes: Route = {
  name: id.payingMembers,
  path: '/:lang?/statistics/paying-members',
  skeleton: <AsyncSkeletonPage />,
};

export default routes;
