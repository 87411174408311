import { of } from 'rxjs';
import evalAuthorization from 'utils/rxjs/eval-authorization';
import type { RouteAuthorization } from 'contracts';
import application from 'services/application';

import id from './id';

const authorization: RouteAuthorization = {
  name: id,
  authorization$: of(true).pipe(
    evalAuthorization(
      () => false,
      () => of(application.LiveJasmin)
    )
  ),
};

export default authorization;
