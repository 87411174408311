import { defineMessages } from 'react-intl';
import type { LocalePerProduct } from 'contracts';

const seo: LocalePerProduct = {
  livejasmin: defineMessages({
    title: {
      id: 'pages.statistics.dashboard.seo.livejasmin.title',
      defaultMessage: 'Dashboard',
    },
    description: {
      id: 'pages.statistics.dashboard.seo.livejasmin.description',
      defaultMessage: 'Statistics Dashboard',
    },
  }),
  oranum: defineMessages({
    title: {
      id: 'pages.statistics.dashboard.seo.oranum.title',
      defaultMessage: 'Dashboard',
    },
    description: {
      id: 'pages.statistics.dashboard.seo.oranum.description',
      defaultMessage: 'Statistics Dashboard',
    },
  }),
};

export default {
  pages: {
    statistics: {
      dashboard: {
        seo,
        navigationMenu: {
          ...defineMessages({
            label: {
              id: 'pages.dashboard.navigationMenu.label',
              defaultMessage: 'Dashboard',
            },
          }),
        },
      },
    },
  },
};
