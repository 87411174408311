import React from 'react';
import type { Route } from 'contracts';

import parentId from '../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../bio'));
const AsyncSkeleton = React.lazy(() => import('../bio.skeleton'));
const AsyncParentSkeleton = React.lazy(() => import('../../details.skeleton'));

const routes: Route[] = [
  {
    parent: parentId.payingMembersDetails,
    name: id.payingMembersBio,
    path: 'bio',
    element: <AsyncPage />,
    skeleton: (
      <AsyncParentSkeleton>
        <AsyncSkeleton />
      </AsyncParentSkeleton>
    ),
  },
];

export default routes;
