import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import(/* webpackPreload: true */ '../confirmation'));

const routes: Route = {
  name: id,
  path: '/:lang?/signup/confirm/:userId/:code',
  element: <AsyncPage />,
  layout: false,
};

export default routes;
