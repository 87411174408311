import type {
  AccountTypeStep,
  CategoryAndDisplayNameStep,
  ChooseExistingPersonStep,
  CreateAccountStep,
  DocumentDetailsStep,
  OranumPersonalDetailsStep,
  PersonalDataStep,
  PersonalDetailsStep,
  UploadDocumentsStep,
} from 'services/api/proxy-registration/data-contracts';

/**
 * Describes the available options for the :step param
 * @example //:lang?/signup/:step(${StepRouteParam})?
 */
enum StepRouteParam {
  NewAccount = 'new-account',
  AccountType = 'select-account-type',
  ConfirmEmailAddress = 'confirm-email-address',
  CategoryAndDisplayName = 'category-and-display-name',
  OwnerDetails = 'owner-details',
  SimplifiedOwnerDetails = 'simplified-owner-details',
  CreateModelProfile = 'create-model-profile',
  IdData = 'id-data',
  UploadDocuments = 'upload-documents',
}

enum SignupStep {
  AccountType = 'account-type',
  CreateAccount = 'create-account',
  EmailConfirmation = 'email-confirmation',
  Documents = 'documents',
  PersonalData = 'personal-data',
}

enum ApiStep {
  CreateAccount = 'create-account',
  AccountType = 'account-type',
  CategoryAndDisplayName = 'category-and-display-name',
  EmailConfirmation = 'email-confirmation',
  PersonalDetails = 'personal-details',
  PersonalData = 'personal-data',
  DocumentDetails = 'document-details',
  UploadDocuments = 'upload-documents',
  AmateurDetails = 'amateur-details',
  OranumPersonalDetails = 'oranum-personal-details',
}

type OmitStatus<T> = T extends object
  ? {
      [K in keyof T]: Omit<T[K], 'status'>;
    }
  : Omit<T, 'status'>;

interface SignupRemoteData {
  accountType?: OmitStatus<AccountTypeStep['account-type']>;
  createAccount?: OmitStatus<CreateAccountStep['create-account']>;
  chooseExistingPerson?: OmitStatus<ChooseExistingPersonStep['choose-existing-person']>;
  categoryAndDisplayName?: OmitStatus<CategoryAndDisplayNameStep['category-and-display-name']>;
  personalData?: OmitStatus<PersonalDataStep['personal-data']>;
  personalDetails?: OmitStatus<
    PersonalDetailsStep['personal-details'] & OranumPersonalDetailsStep['oranum-personal-details']
  >;
  documentDetails?: OmitStatus<DocumentDetailsStep['document-details']>;
  uploadDocuments?: OmitStatus<UploadDocumentsStep['upload-documents']>;
}

interface SignupStepModule {
  rejected: boolean;
  onNextStep: (next: string | undefined | null) => void;
}

interface SignupFormField<T> {
  initialValue: T;
  error: boolean | undefined;
  rejectionReason: string | undefined | null;
  disabled: boolean;
}

type ResponseError<T extends object> = {
  type: string;
  message: string;
  fields: Array<keyof T>;
};

type DocumentAnalysisStatus = 'initial' | 'pending' | 'resolved' | 'rejected';

export { StepRouteParam, SignupStep, ApiStep };
export type { SignupRemoteData, SignupStepModule, SignupFormField, ResponseError, DocumentAnalysisStatus };
