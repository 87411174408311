import { link } from 'services/navigation';
import authorization from 'services/routing/authorization';
import type { NavigationMenuItem } from 'contracts';

import messages from '../../../redirection/legacy/legacy.i18n';

import id from './id';

const menu: NavigationMenuItem[] = [
  {
    name: id.payingMembers,
    parent: 'statistics',
    label: messages.legacy.pages.statistics.payingMembers.navigationMenu.label,
    link: () => link.payingMembers(),
    visibility$: authorization.getAuthorization$('payingMembers'),
    weight: 3,
  },
];

export default menu;
