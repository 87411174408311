import React from 'react';
import type { Route } from 'contracts';

import parentId from '../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../members'));
const AsyncSkeletonPage = React.lazy(() => import('../members.skeleton'));

const routes: Route[] = [
  {
    parent: parentId.payingMembers,
    name: id.payingMembersMembers,
    index: true,
    searchParams: [':search', ':order', ':page'],
    element: <AsyncPage />,
    skeleton: <AsyncSkeletonPage />,
  },
];

export default routes;
