import { link } from 'services/navigation';
import authorization from 'services/routing/authorization';
import type { NavigationMenuItem } from 'contracts';

import messages from '../dashboard.i18n';

import id from './id';

const menu: NavigationMenuItem = {
  name: id,
  label: messages.pages.statistics.dashboard.navigationMenu.label,
  link: () => link[id](),
  visibility$: authorization.getAuthorization$(id),
  weight: 1,
  parent: 'statistics',
};

export default menu;
