import React from 'react';
import type { Route } from 'contracts';

import parentId from '../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../overview'));

const routes: Route = {
  name: id,
  path: 'overview',
  element: <AsyncPage />,
  parent: parentId,
};

export default routes;
