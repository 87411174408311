import React from 'react';
import type { Route } from 'contracts';

import { StepRouteParam } from '../../../contracts';
import parentId from '../../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../create-account'));
const AsyncSkeleton = React.lazy(() => import('../create-account.skeleton'));

const routes: Route = {
  name: id,
  parent: parentId,
  path: StepRouteParam.NewAccount,
  element: <AsyncPage />,
  skeleton: <AsyncSkeleton />,
  layout: false,
};

export default routes;
