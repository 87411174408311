import { link } from 'services/navigation';
import authorization from 'services/routing/authorization';
import type { NavigationMenuItem } from 'contracts';

import messages from '../../../redirection/legacy/legacy.i18n';

import id from './id';

const menu: NavigationMenuItem[] = [
  {
    name: 'incomeOverview',
    label: messages.legacy.pages.statistics.incomeOverview.navigationMenu.label,
    link: () => link.statisticsIncomeOverview(),
    visibility$: authorization.getAuthorization$(id),
    weight: 2,
    parent: 'statistics',
  },
];

export default menu;
