import type { RouteAuthorization } from 'contracts';

import parentAuth from '../../@@global/authorization';

import id from './id';

const authorization: RouteAuthorization = {
  name: id.payingMembersDetails,
  authorization$: parentAuth.authorization$,
};

export default authorization;
