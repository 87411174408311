import React from 'react';
import type { Route } from 'contracts';

import { StepRouteParam } from '../../../contracts';
import parentId from '../../../@@global/id';

import id from './id';

const AsyncPage = React.lazy(() => import('../documents'));

const routes: Route = {
  name: id,
  parent: parentId,
  // eslint-disable-next-line max-len
  path: `:step(${StepRouteParam.IdData}|${StepRouteParam.UploadDocuments})`,
  element: <AsyncPage />,
  layout: false,
};

export default routes;
