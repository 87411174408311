import React from 'react';
import type { Route } from 'contracts';

import id from './id';

const AsyncPage = React.lazy(() => import('../income-overview'));
const AsyncSkeletonPage = React.lazy(() => import('../income-overview.skeleton'));

const routes: Route = {
  name: id,
  path: '/:lang?/statistics/income-overview',
  element: <AsyncPage />,
  skeleton: <AsyncSkeletonPage />,
};

export default routes;
